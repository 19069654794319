<template>
  <ott-dialog
    v-if="packages.isOpenEditModal"
    :is-open="packages.isOpenEditModal"
    :show-footer="false"
    :width="1080"
    modal-title="Edit Package"
    @outside="closeEditModal"
    @resetDataModal="closeEditModal"
  >
    <template #modalBody>
      <div class="G-flex">
        <div
          class="
            P-left-block P-background-neutral7
            G-flex-column G-justify-between left
          "
        >
          <div>
            <ul class="P-left-block__list list">
              <li
                v-for="(item, index) in packages.editTabs"
                :key="index"
                :class="{ 'active-tab listActive': index === packages.activeTab }"
                @click="selectTab(item)"
                class="P-left-block__list-item"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="P-right-block G-flex-column G-justify-between right">
          <div class="P-right-block__main-content">
            <GeneralInfo v-if="packages.activeTab === 0" />
            <Prices v-else />

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttButton from "@/components/vuetifyComponents/OttButton";
import GeneralInfo from "./GeneralInfo";
import Prices from "./Prices";
export default {
  name: "EditPackage",
  components: { OttButton, Prices, GeneralInfo, OttButtonLoader, OttDialog },
  computed: {
    ...mapState({
      packages: (state) => state.packagesModule,
      isSaved: (state) => state.appModule.isSaved
    }),
  },
  methods: {
    ...mapMutations({
      setIsOpenEditModal: "packagesModule/setIsOpenEditModal",
      nextTab: "packagesModule/nextTab",
      resetGeneralInfo: "packagesGeneralInfo/resetGeneralInfo",
      resetPrices: "packagesModule/resetPrices",
      setActiveTab: "packagesModule/setActiveTab",
      resetPricesFilter: "packagesModule/resetPricesFilter",
      setShowCancelBtn: "packagesModule/setShowCancelBtn",
      resetFilterPricesModel: "packagesModule/resetFilterPricesModel",
    }),
    closeEditModal() {
      this.setIsOpenEditModal(false);
      this.resetGeneralInfo();
      this.resetPrices();
      this.resetFilterPricesModel();
      this.setActiveTab(0);
      this.resetPricesFilter();
      this.setShowCancelBtn(false);
    },
    selectTab(item) {
      if (item.title === "generalInfo") {
        this.packages.activeTab = 0;
      } else {
        this.packages.activeTab = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
.P-left-block {
  width: 226px;
  height: 685px;
  padding: 16px 14px 24px;
  &__list {
    margin-left: 0;
    padding-left: 0;
    &-item {
      padding: 7px 11px;
      border-radius: 4px;
      font-size: $txt14;
      font-weight: $semiBold;
      color: $secondary-color;
      cursor: pointer;
    }
  }
  .active-tab {
    background-color: $neutral-color25;
  }
}

.theme--dark {
  .P-left-block {
    .active-tab {
      background-color: transparent;
    }
  }
}
.P-right-block {
  width: 100%;
  &__main-content {
    padding: 12px 24px;
  }
  &__bottom {
    border-top: 1px solid $neutral-color;
    padding: 24px;
    .cancel-btn {
      margin-right: 24px;
    }
  }
}
</style>