import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      actionList: [
        {
          title: 'Disable',
          value: 'disable'
        },
        {
          title: 'Enable',
          value: 'enable'
        }
      ],
      multipleActions: [
        {
          title: 'Disable',
          value: 'disable'
        },
        {
          title: 'Enable',
          value: 'enable'
        }
      ],
      selectedPackageId: null,
      warningModalBtnText: "Disable",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      loading: false
    }
  },
  async created() {
    this.$hubEventBus.$on("package-info", this.handlePackageInfo); // if loggedIn get permissions with WS
    this.loading = true
    await this.getPackagesSettings()
    this.createColumnConfigData()
    this.hideShowColumns()
    this.sortColumn();
    await this.getPackagesList(this.packages.filterData).finally(() => this.loading = false)
    this.resetSelectedList()
  },
  destroyed() {
    this.setFilterPage(1)
  },
  beforeDestoy() {
    this.$hubEventBus.$off("package-info", this.handlePackageInfo); // if loggedIn get permissions with WS
  },
  computed: {
    ...mapState({
      // filterModel: state => state.packagesModule.filterModel,
      dataTable: state => state.packagesModule.dataTable,
      authUser: (state) => state.auth.user,

      // important variables vor get list and  change  columns
      filter: state => state.packagesModule.filter,
      packages: state => state.packagesModule,
      defaultSettings: state => state.packagesModule.defaultSettings,
      updateColumnSettingsCommit: state => state.packagesModule.updateColumnSettingsCommit,
      updateColumnsFromBack: state => state.packagesModule.updateColumnsFromBack,
      isDefault: state => state.packagesModule.isDefault,
    }),

    defaultColumnConfigs() {
      return [
        {
          title: "ID",
          isTranslate: false,
          style: {minWidth: 160},
          key: "number",
          isShow: true,
          isDrag: true,
          "defaultHide": true,
          "defaultDragIndex": 0,
          "mainIndex": 0,
          sortData: {
            isSort: true,
            sortKey: "number",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${row.number}</p>`
        },
        {
          title: "Package Name",
          isTranslate: false,
          style: {minWidth: 160},
          isShow: true,
          key: "name",
          isDrag: true,
          "defaultHide": true,
          "defaultDragIndex": 1,
          "mainIndex": 1,
          sortData: {
            isSort: true,
            sortKey: "name",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${this.getPackageName(row.name)}</p>`
        },
        {
          title: "Status",
          isTranslate: false,
          key: "state",
          "defaultHide": true,
          "defaultDragIndex": 2,
          "mainIndex": 2,
          style: {minWidth: 160},
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "state",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${this.getStatus(row.state)}</p>`
        },
        {
          title: "Services",
          isTranslate: false,
          key: "aEnable",
          style: {minWidth: 160},
          isShow: true,
          isDrag: true,
          "defaultHide": true,
          "defaultDragIndex": 3,
          "mainIndex": 3,
          sortData: {
            isSort: true,
            sortKey: "services",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${this.getServices(row.aEnable, row.tEnable, row.vEnable)}</p>`
        },
        {
          title: "Channels",
          isTranslate: false,
          style: {minWidth: 160},
          isShow: true,
          key: "channelCount",
          isDrag: true,
          "defaultHide": true,
          "defaultDragIndex": 4,
          "mainIndex": 4,
          sortData: {
            isSort: true,
            sortKey: "channels",
            sortValue: ""
          },
          isColumnClicked: true,
          cellView: row => `<p class="G-text-table P-channels">${row.channelCount ? row.channelCount : 0}</p>`,
        },
        // {
        //   title: "Clients Direct",
        //   isTranslate: false,
        //   style: {minWidth: 160},
        //   key: "clientsDirect",
        //   isShow: true,
        //   isDrag: true,
        //   "defaultHide": true,
        //   "defaultDragIndex": 5,
        //   "mainIndex": 5,
        //   sortData: {
        //     isSort: true,
        //     sortKey: "clientsDirect",
        //     sortValue: ""
        //   },
        //   cellView: row => `<p class="G-text-table">${row.clientsDirect}</p>`
        // },
        // {
        //   title: "Clients Total",
        //   isTranslate: false,
        //   style: {minWidth: 160},
        //   isShow: true,
        //   key: "clientsTotal",
        //   isDrag: true,
        //   "defaultHide": true,
        //   "defaultDragIndex": 6,
        //   "mainIndex": 6,
        //   sortData: {
        //     isSort: true,
        //     sortKey: "clientsTotal",
        //     sortValue: ""
        //   },
        //   cellView: row => `<p class="G-text-table">${row.clientsTotal}</p>`
        // },
        {
          title: "Type",
          isTranslate: false,
          style: {minWidth: 160},
          isShow: true,
          isDrag: true,
          key: "type",
          "defaultHide": true,
          "defaultDragIndex": 7,
          "mainIndex": 7,
          sortData: {
            isSort: true,
            sortKey: "type",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${this.getType(row.type)}</p>`
        },
        {
          title: "Buy Price",
          isTranslate: false,
          style: {minWidth: 160},
          key: "buyPrice",
          isShow: true,
          isDrag: true,
          "defaultHide": true,
          "defaultDragIndex": 8,
          "mainIndex": 8,
          sortData: {
            isSort: true,
            sortKey: "buyPrice",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">
                               ${ row.buyPrice > 0 ? '$' + row.buyPrice.toFixed(2) : 
                               '<span class="G-badge G-pending-badge G-flex G-align-center">Not defined</span>' }
                            </p>`
        },
        {
          title: "Client Price",
          isTranslate: false,
          style: {minWidth: 160},
          isShow: true,
          isDrag: true,
          key: "clientPrice",
          "defaultHide": true,
          "defaultDragIndex": 9,
          "mainIndex": 9,
          sortData: {
            isSort: true,
            sortKey: "clientPrice",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">
                               ${ row.clientPrice > 0 ? '$' + row.clientPrice.toFixed(2) : 
                               '<span class="G-badge G-pending-badge G-flex G-align-center">Not defined</span>' }
                            </p>`
        },
        {
          title: "Resale Price",
          isTranslate: false,
          style: {minWidth: 160},
          isShow: true,
          isDrag: true,
          key: "resalePrice",
          "defaultHide": true,
          "defaultDragIndex": 10,
          "mainIndex": 10,
          sortData: {
            isSort: true,
            sortKey: "resalePrice",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">
                               ${ row.resalePrice > 0 ? '$' + row.resalePrice.toFixed(2) : 
                               '<span class="G-badge G-pending-badge G-flex G-align-center">Not defined</span>' }
                            </p>`
        },
      ]
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
    }
  },

    warningModalHeading() {
      return `${this.warningModalBtnText} Package(s) ?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Packages! <br />  If you're not sure, you can cancel this operation.`
    },
  },

  methods: {

        createToastMessage(response) {
            const toastOptions = {
              msg: response.message,
              position: "toast-bottom-right",
              defaultTimeout: 500
            }
      
            if (response.success) {
              toastOptions.type = "success"
              toastOptions.style = { 
                backgroundColor: "#01B2B8", 
                width: "max-content", 
                textTransform: "capitalize",
                opacity: '1'
              }
      
              this.$toastr.Add(toastOptions);
            } else {
              toastOptions.type = "error",
              toastOptions.style = { 
                backgroundColor: "red", 
                width: "max-content", 
                textTransform: "capitalize",
                opacity: '1'
              }
      
              this.$toastr.Add(toastOptions);
            }
        },

    async handlePackageInfo(info) {
      //After send invoice, create toast for show message
      this.createToastMessage({
        message: info.message,
        success: info.status,
      })
      if (info.status) {
        await this.getPackagesList(this.packages.filterData)
        this.resetSelectedList()
      }
    },
    ...mapActions({
      getPackagesList: 'packagesModule/getPackagesList',
      exportPackagesList: 'packagesModule/exportPackagesList',
      editPackage: 'packagesModule/editPackage',
      getPackage: 'packagesGeneralInfo/getPackage',
      disablePackage: 'packagesModule/disablePackage',
      multipleDisableEnablePackage: 'packagesModule/multipleDisableEnablePackage',
      getChannelsByPackageId: 'packagesModule/getChannelsByPackageId',
      getPackagesSettings: 'packagesModule/getPackagesSettings',
      channelsSync: 'packagesModule/channelsSync'
    }),

    ...mapMutations({
      setIsOpenEditModal: 'packagesModule/setIsOpenEditModal',
      setFilterPage: 'packagesModule/setFilterPage',
      sortColumn: 'packagesModule/sortColumn',
      setPackageId: 'packagesModule/setPackageId',
      setDefaultSettings: 'packagesModule/setDefaultSettings',
      setData: 'packagesModule/setData',
      setLimit: 'packagesModule/setLimit',
      setIsOpenChannelsModal: 'packagesModule/setIsOpenChannelsModal',
      resetFilter: 'packagesModule/resetFilter',
      // filterPackages: 'packagesModule/filterPackages'
    }),

    hideShowColumns() {
      if (this.authUser.rolesInfo.admin) {
        this.columnConfigData.map((item) => {
          if (
            item.key === 'state' || 
            item.key === 'type' || 
            item.key === 'buyPrice' || 
            item.key === 'clientPrice' ||
            item.key === 'resalePrice'
          ) {
            item.isShow = true
            item.unshowForOtherUsers = false
            item.disabled = false
          }
        })
      } else {
        this.columnConfigData.map((item) => {
          if (
            item.key === 'state' || 
            item.key === 'type' || 
            item.key === 'buyPrice' || 
            item.key === 'clientPrice' ||
            item.key === 'resalePrice'
          ) {
            item.isShow = false
            item.unshowForOtherUsers = true
            item.disabled = true
          }
        })
      }
    },

    /**
     * Open channels list modal
     */
    async openChannelsListModal(row, columnTitle) {
      let packageId = row.id

      this.setPackageId(packageId)
      await this.getPackage({packageId})
      await this.getChannelsByPackageId({packageId})
      this.setIsOpenChannelsModal(true)
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })
      this.sortColumn();
      await this.getPackagesList(this.packages.filterData)
      this.resetSelectedList()
    },

    /**
     * Reset filters
     */
    // async resetData() {
    //   // this.filterModel.reset()
    //   this.filterPackages()
    //   await this.getPackagesList(this.packages.filterData)
    //   this.resetSelectedList()
    // },


    /**
     * Reset filters
     */
    async exportData() {
      // this.filterPackages();
      await this.getPackagesList({...this.packages.filterData, excel: true})
      this.resetSelectedList()
    },

    /**
     * Sort table data
     */
    async sortColumnData(data) {
      this.sortColumn(data)
      await this.getPackagesList(this.packages.filterData)
    },

    /**
     * Update Packages list
     */
    async updatePackagesList(page) {
      if (page) {
        this.setFilterPage(page)
      }
      this.sortColumn();
      await this.getPackagesList(this.packages.filterData)
      this.resetSelectedList()
    },

    /**
     * Functions for packages list data
     */
    getStatus(state) {
      if (!state) {
        return '<span class="G-badge G-disabled-badge">Disabled</span>'
      } else {
        return ''
      }
    },
    getType(type) {
      if (type === 1) {
        return '<span class="G-badge G-base-badge G-flex G-align-center">Base</span>'
      } else {
        return '<span class="G-badge G-pending-badge G-flex G-align-center">Advanced</span>'
      }
    },
    getServices(archiveService, timeshiftService, vodService) {
      if (archiveService && timeshiftService && vodService) {
        return 'Arch, TS, VOD'
      }
      if (archiveService && timeshiftService && !vodService) {
        return 'Arch, TS'
      }
      if (archiveService && !timeshiftService && vodService) {
        return 'Arch, VOD'
      }
      if (!archiveService && timeshiftService && vodService) {
        return 'TS, VOD'
      }
      if (!archiveService && !timeshiftService && !vodService) {
        return ''
      }
      if (vodService) {
        return 'VOD'
      }
      if (archiveService) {
        return 'Arch'
      }
      if (timeshiftService) {
        return 'TS'
      }
    },
    getPackageName(nameArray) {
      if (nameArray && nameArray.length) {
        let engName = nameArray.filter(name => name.lang === 'en' || name.lang === 'EN')
        let ruName = nameArray.filter(name => name.lang === 'ru' || name.lang === 'RU')
        if (engName && engName.length) {
          return engName[0].name
        } else {
          return ruName[0].name
        }
      }
    },

    /**
     * Select action
     */
    async selectAction() {
      const [event, data, actionName] = arguments

      const packageData = this.getDataFromActions(data)
      let packageId = data.rowData.id
      this.selectedPackageId = packageId

      if (actionName !== "edit") {
        if (this.selectedArrowList.length) {
          this.selectMultipleAction(actionName)
        } else {
          this.openWarningModal(actionName)
        }
      } else {
        this.setPackageId(packageId)
        await this.getPackage({packageId})
        this.setIsOpenEditModal(true)
      }
    },
    actionItems(rowData) {
      let actions = JSON.parse(JSON.stringify(this.actionList))

      if (this.permissionIs('updatePackage')) {
        actions.unshift({
          title: 'Edit',
          value: 'edit'
        })
      }
      
      if (this.permissionIs('disableEnablePackage')) {
        if (rowData?.state) {
          actions = actions.filter(item => item.value !== 'enable')
        } else {
          actions = actions.filter(item => item.value !== 'disable')
        }
      }
      
      if (!rowData?.allowDisable) {
        actions = actions.filter(item => item.value !== 'disable' && item.value !== 'enable')
      }

      return actions
    },

    /**+
     * Select multiple actions
     */
    selectMultipleAction(actionName) {
      this.selectedPackageId = this.selectedArrowList.map(x => x.id)
      this.openWarningModal(actionName)
    },

    openWarningModal(actionName) {
      this.warningModalIsLoading = false;
      
      switch (actionName) {
        case 'disable': {
          this.warningModalBtnText = "Disable"
          this.warningModalIsDangerButton = true
          break
        }
        case 'enable': {
          this.warningModalBtnText = "Enable"
          this.warningModalIsDangerButton = false
          break
        }
      }

      this.setData({showWarningModal: true})
    },

    closeWarningModal() {
      this.warningModalIsLoading = false
      this.setData({showWarningModal: false})
    },

    async refreshPage() {
      this.setData({ isTableLoading: true })
      this.resetFilter()
      await this.channelsSync()
    },

    async exportExcel() {
      this.setData({ isTableLoading: true })
      this.resetFilter()
      await this.channelsSync()
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.closeWarningModal()
      });
    },

    async warningModalBtnFunction() {
      let state;
      this.warningModalIsDangerButton ? state = false : state = true
      this.warningModalIsLoading = true

      if (Array.isArray(this.selectedPackageId)) { // multiple actions
        const data = {
          action: this.warningModalIsDangerButton ? 2 : 1,
        }

        if (!this.isSelectedAll && this.selectedPackageId) {
          data.packages = [...this.selectedPackageId]
        }

        if (this.isSelectedAll) {
          data.all = true
        }

        await this.multipleDisableEnablePackage(data)
      } else { // action from list
        await this.disablePackage({packageId: this.selectedPackageId, status: state})
      }
    },

    // TODO CHANGE_SORT
    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getPackagesSettings()
      this.createColumnConfigData()
      this.hideShowColumns()
    }
  }
}
