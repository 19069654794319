<template>
  <div :class="{'P-modal-component-header G-justify-between G-align-center' : addStyles }">
    <div class="G-flex G-align-center">
      <div v-if="showBackIcon" @click="goBack">
        <span class="mdi mdi-arrow-left" />
      </div>
      <h3 class="secondary--text" v-html="titleText" />
    </div>

    <slot name="middle" />

    <div v-if="isAdd" class="icon-cont" @click="click">
      <span class='mdi mdi-plus neutral--text'/>
    </div>
    <div v-if="isEdit" class="icon-cont" @click="click">
      <span class='mdi mdi-pencil neutral--text'/>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'TitleComponent',
    props: {
      addStyles: {
        type: Boolean,
        default: true
      },
      isAdd: {
        type: Boolean,
        default: false
      },
      click: {
        type: Function,
        default: () => null
      },
      titleText: {
        type: String,
        default: ''
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      showBackIcon: {
        type: Boolean,
        default: false
      },
      goBack: {
        type: Function,
        default: () => null
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "src/assets/scss/variables";
  .P-modal-component-header {
    margin-bottom: 10px;
    min-height: 40px;

    h3 {
      font-size: $txt16;
      font-weight: $semiBold;
      text-transform: capitalize;
    }
  }

  .mdi-arrow-left {
    font-size: $txt20;
    color: $secondary-color;
    margin-right: 8px;
    cursor: pointer;
  }
</style>