<template>
  <div class="P-general-info">
    <title-component title-text="General Info" />
    <div class="G-flex">
      <p class="P-service G-flex G-align-center">
        <span class="P-service__name">Timeshift</span>
        <span class="P-service__info">
          {{ generalInfo.timeshiftService ? "YES" : "NO" }}
        </span>
      </p>
      <p class="P-service G-flex G-align-center">
        <span class="P-service__name">Archive</span>
        <span class="P-service__info">
          {{ generalInfo.archiveService ? "YES" : "NO" }}
        </span>
      </p>
      <p class="P-service G-flex G-align-center">
        <span class="P-service__name">VOD</span>
        <span class="P-service__info">
          {{ generalInfo.vodService ? "YES" : "NO" }}
        </span>
      </p>
    </div>
    <div>
      <ott-switch label="Disable" v-model="disable" />
    </div>
    <div class="P-packages-info G-flex">
      <ott-input
        v-model="packageName"
        label="Package Name"
        class="P-package-name"
        disabled
      />
      <ott-select
        v-model="packageType"
        :items="packageTypes"
        label="Type"
        selected-text="type"
        class="P-package-type"
      />
    </div>
    <div class="G-flex P-block">
      <ott-checkbox
        v-model="forClients"
        label="For Clients"
        class="P-checkbox"
      />
      <ott-checkbox label="For Resale" class="P-checkbox" v-model="forResale" />
    </div>
    <div>
      <quill-editor 
        v-model="description" 
        :options="{ placeholder: 'Description' }"
        :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
      />
    </div>
    <div>
      <SaveResetButtons
        :right-click-func="saveData"
        :is-loading="isLoading"
        :show-cancel-btn="false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

// components
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import SaveResetButtons from "@/components/SaveResetButtons";
import TitleComponent from "@/components/TitleComponent";

export default {
  name: "GeneralInfo",
  components: {
    SaveResetButtons,
    OttCheckbox,
    OttSelect,
    OttInput,
    OttSwitch,
    TitleComponent,
  },
  computed: {
    ...mapState({
      packageId: (state) => state.packagesModule.packageId,
      packages: (state) => state.packagesModule,
      generalInfo: (state) => state.packagesGeneralInfo.generalInfo,
      packageTypes: (state) => state.packagesGeneralInfo.packageTypes,
      packageInfo: (state) => state.packagesGeneralInfo.packageInfo,
      isLoading: (state) => state.appModule.isLoading
    }),
    packageName: {
      get() {
        return this.generalInfo.packageName;
      },
      set(value) {
        this.setPackageName(value);
      },
    },
    packageType: {
      get() {
        return this.generalInfo.packageType;
      },
      set(value) {
        this.setPackageType(value);
      },
    },
    forClients: {
      get() {
        return this.generalInfo.forClients;
      },
      set(value) {
        this.setForClients(value);
      },
    },
    forResale: {
      get() {
        return this.generalInfo.forResale;
      },
      set(value) {
        this.setForResale(value);
      },
    },
    description: {
      get() {
        return this.generalInfo.description;
      },
      set(value) {
        this.setDescription(value);
      },
    },
    disable: {
      get() {
        return this.generalInfo.disable;
      },
      set(value) {
        this.setDisable(value);
      },
    },
  },
  async created() {
    if (this.packageId) {
      await this.getPackage({ packageId: this.packageId })
      await this.getPrice({ packageId: this.packageId, type: true }).then(clientPrice => {
        if (clientPrice.prices && clientPrice.prices.length) {
          this.setClientType("clients")
        } else {
          this.getPrice({ packageId: this.packageId, type: false }).then(resalePrice => {
            if (resalePrice.prices && resalePrice.prices.length) {
              this.setClientType("resale")
            }
          })
        }
      })
    }
  },
  methods: {
    ...mapActions({
      getPackage: "packagesGeneralInfo/getPackage",
      editPackage: "packagesModule/editPackage",
      getPrice: "packagesModule/getPrice",
    }),
    ...mapMutations({
      setPackageName: "packagesGeneralInfo/setPackageName",
      setPackageType: "packagesGeneralInfo/setPackageType",
      setForClients: "packagesGeneralInfo/setForClients",
      setForResale: "packagesGeneralInfo/setForResale",
      setDescription: "packagesGeneralInfo/setDescription",
      setDisable: "packagesGeneralInfo/setDisable",
      resetGeneralInfoNotServices: "packagesGeneralInfo/resetGeneralInfoNotServices",
      setClientType: 'packagesModule/setClientType',
    }),
    async saveData() {
      const body = {
        name: [
          {
            lang: this.packageInfo.name[0].lang,
            name: this.generalInfo.packageName,
          },
        ],
        description: [
          {
            lang:
              this.packageInfo.description &&
              this.packageInfo.description.length
                ? this.packageInfo.description[0].name
                : "EN",
            name: this.generalInfo.description,
          },
        ],
        forClients: this.generalInfo.forClients,
        forResale: this.generalInfo.forResale,
        type: this.generalInfo.packageType === "base" ? 1 : 2,
        status: this.generalInfo.disable ? 0 : 1,
      };
      await this.editPackage({ packageId: this.packageId, body });
    },
    resetData() {
      this.resetGeneralInfoNotServices();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.theme--dark {
  .P-service__info {
    color: white !important;
  }
}
.P-general-info {
  .P-service {
    position: relative;
    margin-bottom: 0;
    &__name {
      font-size: $txt12;
      font-weight: $semiBold;
      color: $neutral-color;
      padding-right: 16px;
      padding-left: 14px;
    }
    &__info {
      font-size: $txt14;
      color: $secondary-color;
      padding-right: 16px;
    }
    &:first-child {
      .P-service__name {
        padding-left: 0;
      }
    }
    &:not(:first-child) {
      &:after {
        content: "";
        background-color: $neutral-color;
        width: 1px;
        height: 14px;
        position: absolute;
        bottom: 4px;
      }
    }
  }
  .P-packages-info {
    margin-top: 16px;
    .P-package-name {
      max-width: 372px;
      width: 100%;
      margin-right: 22px;
    }
    .P-package-type {
      max-width: 240px;
      width: 100%;
    }
  }
  .P-block {
    margin-left: -3px;
    padding: 12px 0;
    .P-checkbox {
      margin-right: 22px;
    }
  }
  .P-save-btn {
    margin-top: 35px;
    width: fit-content;
  }
}

::v-deep {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-text-field__details {
    display: none;
  }
  .v-messages.theme--light {
    display: none;
  }
}
</style>