<template>
    <div class="P-prices">
        <div class="P-inputs">
            <div>
                <ott-select
                    v-model="filterPricesModel.discount"
                    :items="discountList"
                    :multiple="false"
                    :persistentHint="true"
                    label="Discounts"
                    selected-text="name"
                    clear
                    @emitFunc="pricesFilter('discount')"
                />
            </div>
            <div>
                <ott-select
                    v-model="filterPricesModel.priceGroup"
                    :items="priceGroupLists"
                    :multiple="false"
                    :persistentHint="true"
                    label="Price Group"
                    selected-text="name"
                    is-translate
                    clear
                    @emitFunc="pricesFilter('priceGroup')"
                />
            </div>
            <div>
                <ott-select
                    v-model="filterPricesModel.period"
                    :items="packages.periodList"
                    label="Period"
                    selected-text="name"
                    clear
                />
            </div>
        </div>
        <div class="G-flex G-align-center">
            <div class="P-grouping-mode">
                <ott-select
                    v-model="filterPricesModel.groupingMode"
                    :items="groupingModeItems"
                    label="Grouping Mode"
                    selected-text="name"
                    clear
                />
            </div>
            <div>
                <v-radio-group
                    v-model="pricesFor"
                    @change="pricesFilter('clientType', $event)"
                >
                    <div class="G-flex P-radio-buttons">
                        <div class="P-radio">
                            <ott-radio
                                :value="priceForTypes.clients"
                                label="Clients Prices"
                            />
                        </div>
                        <div class="P-radio">
                            <ott-radio
                                :value="priceForTypes.resale"
                                label="Resale Prices"
                            />
                        </div>
                    </div>
                </v-radio-group>
            </div>
        </div>

        <div class="mt-2">
            <title-component title-text="Price List" />
            <div class="P-table border-bottom-0">
                <div class="P-table-header border-bottom-0">
                    <ul class="G-flex">
                        <li v-for="(column, columnIndex) in priceListHeader" :key="columnIndex">{{ column }}</li>
                        <li></li>
                    </ul>
                </div>
            </div>
            <div class="P-table">
                <div class="P-table-body">
                    <ul v-for="(item, index) in allPriceList" :key="index" class="G-flex">
                        <li class="P-price-interval">
                            <div>{{ item.md }}</div>
                            <div class="P-delete-btn" @click="openRemovePriceModal(item)">
                                <span class="mdi mdi-close" />
                            </div>
                        </li>
                        <li class="P-price-group">
                            <div>{{ selectedPriceGroupName }}</div>
                        </li>
                        <li v-for="(room, roomIndex) in item.rooms" :key="roomIndex" class="P-price-column">
                            <div :class="packages.editedPriceId !== room.id ? '' : 'P-edit-price'">
                                <div class="G-flex G-justify-between G-align-center show-price" v-if="packages.editedPriceId !== room.id">
                                    <v-tooltip v-if="room.priceParent !== null" top color="primary">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                                style="cursor: pointer"
                                            >
                                                {{
                                                    new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                    }).format(room.price)
                                                }}
                                            </span>
                                        </template>
                                        <div style="font-weight: 600">
                                            <span class="text-decoration-line-through">
                                                {{
                                                    new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                    }).format(room.priceParent)   
                                                }}
                                            </span>
                                            &nbsp; / &nbsp;
                                            <span>
                                                {{
                                                    new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                    }).format(room.priceParentDiscounted)
                                                }}
                                            </span>
                                        </div>
                                    </v-tooltip>
                                    <div v-else>{{ 
                                        new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2,
                                        }).format(room.price)
                                    }}</div>
                                    <div class="mdi mdi-pencil" @click="editPrice(room)" />
                                </div>
                                <div v-else>
                                    <div>
                                        <ott-input
                                            v-model.number="room.price"
                                            v-click-outside="onClickOutside"
                                            type="number"
                                            :min="1"
                                            @keyup="onClickOutside"
                                            is-percent
                                            autofocus
                                            :error="!room.price"
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <WarningModal
                v-if="showWarningModal"
                :isModalOpen="showWarningModal"
                modal-heading="Delete price ?"
                :modal-text="warningModalText"
                btn-text="Delete"
                :is-loading="warningModalIsLoading"
                @closeModal="showWarningModal = false"
                @btnFunction="removePrice(packages.deletedPrice)"
            />
            <div class="P-add-icon G-flex G-justify-center G-align-center" @click="addRow">
                <span class='mdi mdi-plus neutral--text'/>
            </div>
            <AddPrice v-if="packages.isOpenAddPriceModal" />
            <SaveResetButtons
                :right-click-func="savePrices"
                :show-cancel-btn="false"
                :is-loading="isLoading"
            />
        </div>
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";

    // components
    import OttSelect from "@/components/vuetifyComponents/OttSelect";
    import OttRadio from "@/components/vuetifyComponents/OttRadio";
    import TitleComponent from "@/views//clients/components/TitleComponent";
    import AddPrice from "./AddPrice";
    import SaveResetButtons from "@/views/clients/components/SaveResetButtons";
    import OttInput from "@/components/vuetifyComponents/OttInput";
    import WarningModal from "@/components/WarningModal";

    export default {
        name: "PriceList",

        components: {
            WarningModal,
            OttInput,
            SaveResetButtons,
            AddPrice,
            TitleComponent,
            OttRadio,
            OttSelect
        },

        data() {
            return {
                priceListHeader: [
                    'M/D', 'Price Group', '1Rm', '2Rm', '3Rm', '4Rm', '5Rm'
                ],
                showWarningModal: false,
                warningModalIsLoading: false,
            }
        },

        computed: {
            ...mapState({
                packages: state => state.packagesModule,
                priceForTypes: state => state.packagesModule.pricesFor,
                addPrice: state => state.packagesModule.addPrice,
                priceGroupAdmin: state => state.priceGroupAdmin,
                calculateByPercents: state => state.packagesModule.calculateByPercents,
                packageId: state => state.packagesModule.packageId,
                priceGroupAllList: state => state.priceGroupAdmin.priceGroupAllList,
                allPriceList: state => state.packagesModule.allPriceList,
                discounts: state => state.packagesModule.discountList,
                filterPricesModel: state => state.packagesModule.filterPricesModel,
                filterDataPrices: state => state.packagesModule.filterDataPrices,
                editedPrice: state => state.packagesModule.editedPrice,
                isLoading: state => state.appModule.isLoading
            }),

            discountList() {
                return this.discounts.filter(item => item.type === this.pricesFor);
            },

            priceGroupLists() {
                let priceGroups = [];
                this.pricesFor === 'clients' 
                  ? priceGroups = this.priceGroupAdmin.priceGroupAllList.filter(item => item.type === 1)
                  : priceGroups = this.priceGroupAdmin.priceGroupAllList.filter(item => item.type === 2)

                priceGroups.unshift({
                    name: [{ name: 'Default' }],
                    value: ''
                })

                return priceGroups
            },

            selectedPriceGroupName() {
                const priceGroupId = this.filterPricesModel.priceGroup;
                const priceGroup = this.priceGroupLists.filter(item => item.id === priceGroupId)
                return priceGroup[0] && priceGroup[0].name !== undefined ? this.getName(priceGroup[0].name) : ''
            },
       
            numberOfMonthDay: {
                get() {
                    return this.addPrice.numberOfMonthDay
                },
                set(value) {
                    this.setNumberOfMonthDay(value)
                }
            },
            groupingModeItems: {
                get() {
                    return this.packages.groupingModeItems;
                }
            },
            priceGroup: {
                get() {
                    return this.calculateByPercents.priceGroup
                },
                set(value) {
                    this.setPriceGroup(value)
                }
            },
            discount: {
                get() {
                    return this.calculateByPercents.discount
                },
                set(value) {
                    this.setDiscount(value)
                }
            },
            pricesFor: {
                get() {
                    return this.addPrice.priceFor
                },
                set(value) {
                    this.setClientType(value)
                }
            },
            warningModalText() {
                return `You're about to delete price! If you're not sure, you can cancel this operation.`
            }
        },

        async created() {
            if (this.packageId) {
                await this.getDiscountList().then(data => {
                    if (data.results && data.results.length) {
                        let allDiscounts = []
                        data.results.map(item => {
                            allDiscounts.push({
                                name: item.generalInfo.name,
                                value: item.id,
                                type: item.generalInfo.type === 2 ? 'clients' : 'resale'
                            })
                        })
                        this.setDiscountList(allDiscounts)
                    }
                })
            }
            await this.getPriceGroupList()
        },

        methods: {
            ...mapActions({
                getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
                getPriceByPriceGroup: 'packagesModule/getPriceByPriceGroup',
                editPriceList: 'packagesModule/editPriceList',
                getDiscountList: 'discountModule/getDiscountList',
                getPrice: 'packagesModule/getPrice'
            }),

            ...mapMutations({
                setIsOpenAddPriceModal: 'packagesModule/setIsOpenAddPriceModal',
                setNumberOfMonthDay: 'packagesModule/setNumberOfMonthDay',
                generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
                setPriceGroup: 'packagesModule/setPriceGroup',
                setDiscount: 'packagesModule/setDiscount',
                setEditedPriceId: 'packagesModule/setEditedPriceId',
                setDiscountList: 'packagesModule/setDiscountList',
                setPriceAllList: 'packagesModule/setPriceAllList',
                filterPrices: 'packagesModule/filterPrices',
                setClientType: 'packagesModule/setClientType',
                setDeletedPrice: 'packagesModule/setDeletedPrice',
                setShowCancelBtn: 'packagesModule/setShowCancelBtn',
                setIsEditLoading: 'packagesModule/setIsEditLoading',
                setData: 'packagesModule/setData',
                setShowPriceList: 'packagesModule/setShowPriceList',
                resetAddPriceInputs: 'packagesModule/resetAddPriceInputs',

            }),

            getName(nameArray) {
                if (nameArray && nameArray.length) {
                    let engName = nameArray.filter(
                    (name) => name.lang === "en" || name.lang === "EN");
                    let ruName = nameArray.filter(
                    (name) => name.lang === "ru" || name.lang === "RU");

                    if (engName && engName.length) {
                        return engName[0].name;
                    } else {
                        return ruName[0].name;
                    }
                }
            },

            addRow() {
                this.setIsOpenAddPriceModal(true)
                this.setNumberOfMonthDay('')
            },

            /**
             * Get price group list
             * **/
            async getPriceGroupList() {
                await Promise.all([this.getPriceGroupByTypeList({ type: 1 }), this.getPriceGroupByTypeList({ type: 2 })]).then(data => {
                    this.generateAllPriceGroups(data)
                })
            },
            getPriceGroup(price) {
                if (price) {
                    let result = this.priceGroupAllList.filter(item => item.id === price)
                    if (result.length && result) {
                        return result[0].name[0].name
                    } else {
                        return ''
                    }
                } else {
                    return ''
                }
            },

            /**
             * Edit one price with input, click outside hide input
             * **/
            editPrice(room) {
                console.log('room ', room)
                this.setData({ editedPrice: room })
                this.setEditedPriceId(room.id)
            },
            onClickOutside() {
                if (this.editedPrice.price) {
                    this.setEditedPriceId('')
                    this.setData({ editedPrice: null })
                }
            },

            async savePrices() {
                this.setIsEditLoading(true)
                const priceList = this.packages.allPriceList

                let editedPrices = []

                priceList.map(item => {
                    item.rooms.map(room => {
                        delete room.id
                        delete room.priceParent
                        delete room.priceParentDiscounted
                    })
                    editedPrices.push({
                        interval: item.md,
                        priceItems: item.rooms
                    })
                })

                let data = {
                    clientType: this.packages.addPrice.priceFor === 'clients',
                    editPrices: editedPrices
                }

                this.filterPricesModel.discount ? data.discount = this.filterPricesModel.discount : ''
                this.filterPricesModel.priceGroup ? data.priceGroup = this.filterPricesModel.priceGroup : ''

                await this.editPriceList({ packageId: this.packageId, data })

                this.filterPricesModel.priceGroup && this.filterPricesModel.discount
                || this.filterPricesModel.priceGroup || this.filterPricesModel.discount
                    ? await this.getPriceByPriceGroup({ packageId: this.packageId, filter: this.filterDataPrices })
                    : await this.getPrice({ packageId: this.packageId, type: this.packages.addPrice.priceFor === 'clients' })

            },
            async pricesFilter(type, value) {
                this.filterPrices()
                if (this.filterDataPrices.priceGroup == null) {
                    delete this.filterDataPrices.priceGroup
                }
                if (type === 'clientType') {
                    delete this.filterDataPrices.priceGroup
                    delete this.filterDataPrices.discount
                    this.filterPricesModel.priceGroup = ''
                }
                await this.getPriceByPriceGroup({ packageId: this.packageId, filter: this.filterDataPrices }).then(data => {
                    if (type === 'clientType' && !data) {
                        this.setShowCancelBtn(true)
                    }
                })
            },

            openRemovePriceModal(item) {
                this.showWarningModal = true
                this.setDeletedPrice(item)
            },

            async removePrice(item) {
                this.warningModalIsLoading = true

                let removePrices = []
                removePrices.push({
                    interval: item.md
                })

                const data = {
                    clientType: this.packages.addPrice.priceFor === 'clients',
                    removePrices: removePrices
                }

                this.filterPricesModel.discount ? data.discount = this.filterPricesModel.discount : ''
                this.filterPricesModel.priceGroup ? data.priceGroup = this.filterPricesModel.priceGroup : ''

                await this.editPriceList({ packageId: this.packageId, data })
                await this.getPrice({ packageId: this.packageId, type: this.packages.addPrice.priceFor === 'clients' }).then(data => {
                    if (!data.prices.length) {
                        this.setShowPriceList(false)
                    }
                })

                this.resetAddPriceInputs();

                this.showWarningModal = false
                this.warningModalIsLoading = false
            }
        }
    }
</script>

<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/simpleTable";

.P-prices {
    margin-top: 24px;
}
.P-inputs {
    display: grid;
    grid-template-columns: 2fr 1.5fr 1.5fr;
    grid-column-gap: 22px;
    align-items: center;
    margin-bottom: 22px;
}
.P-grouping-mode {
    max-width: 318px;
    width: 100%;
    margin-right: 20px;
}
.P-radio {
    margin-right: 22px;
}

.P-button-block {
    margin: 35px 0 16px;
    max-width: 200px;
}

.P-add-icon {
    width: 32px;
    height: 32px;
    margin: 20px auto 0;
    cursor: pointer;

    .mdi-plus {
        font-size: 20px;
    }

    &:hover {
        background-color: $primary-color;
        border-radius: 4px;
        .mdi-plus {
            color: $white!important;
        }
    }
}
::v-deep {
    .P-save-and-reset-block {
        margin-top: 0;
    }
}

.P-table {
    &-header {
        ul {
            li {
                &:nth-child(1) {
                    width: 104px;
                }
                &:nth-child(2) {
                    width: 200px;
                }
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    width: 106px;
                }
            }
        }
    }
    &-body {
        ul {
            border-bottom: 1px solid $neutral-color;
            li {
                > div {
                    padding: 16px 16px;
                    &.P-edit-price {
                        padding: 7px 16px;
                    }
                }

                &.P-price-interval {
                    width: 104px;
                }
                &.P-price-group {
                    width: 200px;
                }
                &.P-price-column {
                    width: 106px;
                }
                &:hover {
                    .mdi-pencil {
                        display: block;
                        cursor: pointer;
                    }
                }
                .mdi-pencil {
                    display: none;
                }
                .P-delete-btn {
                    position: absolute;
                    display: none;
                }
                &:last-child {
                    border: none;
                    .mdi-pencil {
                        margin-right: 14px;
                    }
                }
            }
            &:hover {
                .P-delete-btn {
                    display: block;
                    top: 16px;
                    right: 10px;
                    padding: 0;
                    cursor: pointer;
                    font-size: 16px;
                }
            }
            &:last-child {
                border: none;
            }
        }
    }
}

.theme-light {
    .P-table {
        &-body {
            ul {
                &:hover {
                    background: #F8F8FA;
                }
            }
        }
    }
}

.theme--dark {
  & .P-table {
    background-color: $darkBgLighterDM;
  }
}

.border-bottom-0 {
    border-bottom: unset;
}

</style>