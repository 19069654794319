<template>
  <ott-dialog
    :is-open="packages.isOpenAddPriceModal"
    :show-footer="false"
    :show-header="false"
    :width="400"
    @outside="cancel"
  >
    <template #modalBody>
      <div class="P-add-row-modal">
        <div class="P-add-row-modal__body">
          <p class="P-title">Add row</p>
          <div class="P-radio-group">
            <v-radio-group v-model="packages.addPrice.type">
              <div class="G-flex P-radio-buttons">
                <div class="P-radio">
                  <ott-radio label="day" v-model="priceTypes.day" />
                </div>
                <div class="P-radio">
                  <ott-radio label="month" v-model="priceTypes.month" />
                </div>
              </div>
            </v-radio-group>
          </div>
          <div>
            <ott-input
              v-model="numberOfMonthDay"
              :label="
                packages.addPrice.type === 'day'
                  ? 'Number of day'
                  : 'Number of month'
              "
              :error="$v.addPrice.numberOfMonthDay.$error"
              is-percent
              @emitKeypress="dayOrMonthValue"
            />
          </div>
        </div>
        <div class="P-add-row-modal__footer G-flex G-justify-end">
          <SaveResetButtons
            :right-click-func="addPriceRow"
            :left-click-func="cancel"
            :is-loading="packages.addPrice.isLoading"
            right-label="Add"
          />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import between from "vuelidate/lib/validators/between";
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttInput from "@/components/vuetifyComponents/OttInput";
import SaveResetButtons from "@/views/clients/components/SaveResetButtons";

export default {
  name: "AddPrice",
  components: { SaveResetButtons, OttDialog, OttRadio, OttInput },
  validations: {
    addPrice: {
      numberOfMonthDay: {
        required,
        between: between(1, 1000)
      },
    },
  },
  computed: {
    ...mapState({
      packages: (state) => state.packagesModule,
      priceTypes: (state) => state.packagesModule.priceTypes,
      addPrice: (state) => state.packagesModule.addPrice,
      packageId: (state) => state.packagesModule.packageId,
      filterPricesModel: state => state.packagesModule.filterPricesModel,
    }),
    numberOfMonthDay: {
      get() {
        return this.addPrice.numberOfMonthDay;
      },
      set(value) {
        this.setNumberOfMonthDay(value);
      },
    },
  },
  methods: {
    dayOrMonthValue(event) {
      let char = String.fromCharCode(event.keyCode);

      if (/^[0-9]+\.?[0-9]*$/.test(char)) {
        return true;
      } else {
        return event.preventDefault();
      }
    },
    ...mapActions({
      editPackage: "packagesModule/editPackage",
      getPackage: "packagesGeneralInfo/getPackage",
      getPrice: "packagesModule/getPrice",
      editPriceList: "packagesModule/editPriceList",
      addNewPrice: "packagesModule/addNewPrice"
    }),
    ...mapMutations({
      setIsOpenAddPriceModal: "packagesModule/setIsOpenAddPriceModal",
      setNumberOfMonthDay: "packagesModule/setNumberOfMonthDay",
      setAddPriceIsLoading: "packagesModule/setAddPriceIsLoading",
      resetAddPrice: "packagesModule/resetAddPrice"
    }),

    async addPriceRow() {
      this.$v.addPrice.$touch();

      if (!this.$v.addPrice.$error) {
        this.setAddPriceIsLoading(true)

        let data = {
          interval: this.numberOfMonthDay,
          intervalType: this.packages.addPrice.type === 'day' ? 'd' : 'm',
          clientType: this.packages.addPrice.priceFor === 'clients',
        }

        this.filterPricesModel.priceGroup ? data.priceGroup = this.filterPricesModel.priceGroup : ''
        this.filterPricesModel.discount ? data.discount = this.filterPricesModel.discount : ''

        await this.addNewPrice({ packageId: this.packageId, data })
        await this.getPrice({ packageId: this.packageId, type: this.packages.addPrice.priceFor === 'clients' })
        this.cancel()
      }
    },

    cancel() {
      this.setIsOpenAddPriceModal(false);
      // this.resetAddPrice();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-add-row-modal {
  padding: 24px;
  .P-title {
    font-size: $txt18;
    font-weight: $semiBold;
    color: $secondary-color;
    margin-bottom: 14px;
  }
  .P-radio-group {
    margin-left: -3px;
    margin-bottom: 18px;
  }
  .P-radio {
    margin-right: 22px;
  }
}

::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  .v-text-field__details,
  .v-messages {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot,
  .v-input__slot {
    margin-bottom: 0;
  }
}

.theme--dark {
  .P-title {
    color: white;
  }
}
</style>
