<template>
  <div class="P-filter-packages">
    <div class="P-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      />
    </div>
    <div class="P-block">
      <ott-select
        v-model="filterModel.room"
        :items="packages.rooms"
        @emitFunc="filterData('room')"
        label="Rooms"
        selected-text="text"
        clear
      />
    </div>
    <div class="P-block">
      <ott-select
        v-model="filterModel.clientPriceGroup"
        :items="priceGroupAdmin.clientsPriceGroups"
        :multiple="false"
        @emitFunc="filterData('clientPriceGroup')"
        label="Clients Price Group"
        selected-text="name"
        persistentHint
        isTranslate
        clear
      />
    </div>
    <div class="P-block">
      <ott-select
        v-model="filterModel.clientDiscounts"
        :items="discountModule.clientDiscounts"
        @emitFunc="filterData('clientDiscounts')"
        label="Clients Discount"
        selected-text="name"
        clear
      />
    </div>
    <div class="P-block">
      <ott-select
        v-model="filterModel.providerPriceGroup"
        :items="priceGroupAdmin.providersPriceGroups"
        :multiple="false"
        @emitFunc="filterData('providerPriceGroup')"
        label="Provider Price Group"
        selected-text="name"
        persistentHint
        isTranslate
        clear
      />
    </div>
    <div class="P-block">
      <ott-select
        v-model="filterModel.providerDiscounts"
        :items="discountModule.providerDiscounts"
        @emitFunc="filterData('providerDiscounts')"
        label="Provider Discount"
        selected-text="name"
        clear
      />
    </div>
    <div class="P-block">
      <ott-range-select
        v-model="filterModel.clients"
        :min="filterRangeData.clients[0]"
        :max="filterRangeData.clients[1]"
        @updateRange="filterData"
        @clearData="resetData('clients')"
        label="Clients"
      />
    </div>
    <div class="P-block">
      <ott-range-select
        v-model="filterModel.clientsTotal"
        :min="filterRangeData.clientsTotal[0]"
        :max="filterRangeData.clientsTotal[1]"
        @updateRange="filterData"
        @clearData="resetData('clientsTotal')"
        label="Clients Total"
      />
    </div>
    <div class="G-flex G-align-center G-flex-wrap P-block">
      <ott-checkbox
        v-model="filterModel.liveService"
        @changeCheckbox="filterData('liveService')"
        label="Live"
        class="P-checkbox"
      />
      <ott-checkbox
        v-model="filterModel.archiveService"
        @changeCheckbox="filterData('archiveService')"
        label="Archive"
        class="P-checkbox"
      />
      <ott-checkbox
        v-model="filterModel.timeshiftService"
        @changeCheckbox="filterData('timeshiftService')"
        label="Timeshift"
        class="P-checkbox"
      />
      <ott-checkbox
        v-model="filterModel.vodService"
        @changeCheckbox="filterData('vodService')"
        label="VOD"
        class="P-checkbox"
      />
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

// components
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import SelectedFiltersList from "./SelectedFiltersList";
export default {
  name: "FilterData",
  components: { OttRangeSelect, OttCheckbox, OttSelect, OttInput, SelectedFiltersList },
  computed: {
    ...mapState({
      packages: (state) => state.packagesModule,
      filter: (state) => state.packagesModule.filterData,
      filterModel: (state) => state.packagesModule.filterModel,
      filterRangeData: (state) => state.packagesModule.filterRangeData,
      priceGroupAdmin: state => state.priceGroupAdmin,
      discountModule: state => state.discountModule
    }),
  },
  async mounted() {
    await this.getPriceGroupList()
    await this.getProvidersPriceGroupList()
    await this.getDiscountList()
  },
  methods: {
    ...mapActions({
      getPackagesList: "packagesModule/getPackagesList",
      getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
      getDiscountList: 'discountModule/getDiscountList',
    }),
    ...mapMutations({
      filterPackages: "packagesModule/filterPackages",
      generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
      setProvidersPriceGroups: 'priceGroupAdmin/setProvidersPriceGroups',
      setFilterPage: 'packagesModule/setFilterPage',
      sortColumn: 'packagesModule/sortColumn',
    }),

    async filterData(type) {
      switch (type) {
        case "search": {
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "room": {
          if (!this.filterModel.room) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "clientPriceGroup": {
          if (this.filterModel.clientPriceGroup === '') {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "clientDiscounts": {
          if (!this.filterModel.clientDiscounts) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "providerPriceGroup": {
          if (this.filterModel.providerPriceGroup === '') {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "liveService": {
          if (!this.filterModel.liveService) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "archiveService": {
          if (!this.filterModel.archiveService) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "timeshiftService": {
          if (!this.filterModel.timeshiftService) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
        case "vodService": {
          if (!this.filterModel.vodService) {
            this.filterModel.resetCurrentData(type);
          }
          break;
        }
      }

      this.setFilterPage(1)
      this.filterModel.getFilterCount();
      this.filterPackages();
      this.sortColumn();
      await this.getPackagesList(this.filter);
      this.$emit('resetSelectedList')
    },
    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterPackages();
      await this.getPackagesList(this.filter);
      this.$emit('resetSelectedList')
    },

    /**
     * Get price group list
     * **/
    async getPriceGroupList() {
      await this.getPriceGroupByTypeList({ type: 1 }).then(data => {
        this.generateAllPriceGroups(data)
      })
    },

    async getProvidersPriceGroupList() {
      await this.getPriceGroupByTypeList({ type: 2 }).then(data => {
        this.setProvidersPriceGroups(data)
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.P-filter-packages {
  .P-checkbox {
    padding-right: 20px;
  }
  .P-block {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 20px;
      margin-left: -3px;
    }
  }
}

::v-deep {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
    display: none;
  }
}
</style>
