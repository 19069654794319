<template>
  <div>
    <div class="G-align-center G-justify-between P-top-block">
      <div class="P-package G-flex">
        <p class="P-package__name">{{ generalInfo.packageName }}</p>
        <p class="P-package__price">$200</p>
      </div>
      <div v-if="generalInfo.packageType === 'base'">
        <span class="G-badge G-base-badge">Base</span>
      </div>
      <div v-else>
        <span class="G-badge G-pending-badge">Advanced</span>
      </div>
    </div>
    <div v-if="packages.showPriceList">
      <PriceList />
    </div>
    <div v-else class="P-add-price">
      <title-component title-text="Type" />
      <v-radio-group v-model="packages.addPrice.type">
        <div class="G-flex P-radio-buttons">
          <div class="P-radio">
            <ott-radio label="day" :value="priceTypes.day" />
          </div>
          <div class="P-radio">
            <ott-radio label="month" :value="priceTypes.month" />
          </div>
        </div>
      </v-radio-group>
      <div class="P-input-block G-flex G-align-center">
        <ott-input
          v-model="numberOfMonthDay"
          :label="
            packages.addPrice.type === 'day'
              ? 'Number of day'
              : 'Number of month'
          "
          :error="$v.addPrice.numberOfMonthDay.$error"
          is-percent
          custom-input-class="P-number-month"
        />
        <v-radio-group v-model="pricesFor" @change="clientTypeChange($event)">
          <div class="G-flex P-radio-buttons">
            <div class="P-radio">
              <ott-radio label="Clients Prices" :value="pricesForTypes.clients" />
            </div>
            <div class="P-radio">
              <ott-radio label="Resale Prices" :value="pricesForTypes.resale" />
            </div>
          </div>
        </v-radio-group>
      </div>
      <div class="P-default-prices">
        <title-component title-text="Default Prices" />
        <div class="P-default-prices__inputs">
          <ott-input
            v-model="firstRoom"
            :error="$v.addPrice.firstRoom.$error"
            label="1 Room"
            is-percent
          />
          <ott-input
            v-model="secondRoom"
            :error="$v.addPrice.secondRoom.$error"
            label="2 Room"
            is-percent
          />
          <ott-input
            v-model="thirdRoom"
            :error="$v.addPrice.thirdRoom.$error"
            label="3 Room"
            is-percent
          />
          <ott-input
            v-model="fourthRoom"
            :error="$v.addPrice.fourthRoom.$error"
            label="4 Room"
            is-percent
          />
          <ott-input
            v-model="fifthRoom"
            :error="$v.addPrice.fourthRoom.$error"
            label="5 Room"
            is-percent
          />
        </div>
      </div>
      <save-reset-buttons
        :is-loading="isLoading"
        :left-click-func="cancel"
        :right-click-func="() => saveData()"
        right-label="Save"
        :show-cancel-btn="packages.showCancelBtn"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import between from "vuelidate/lib/validators/between";

// components
import PriceList from "./PriceList";
import AddPrice from "./AddPrice";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import TitleComponent from "@/views/clients/components/TitleComponent";
import SaveResetButtons from "@/views/clients/components/SaveResetButtons";
import OttInput from "@/components/vuetifyComponents/OttInput";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

export default {
  name: "Prices",
  components: {
    ErrorMessageContainer,
    PriceList,
    OttInput,
    SaveResetButtons,
    TitleComponent,
    OttRadio,
    OttSelect,
    AddPrice,
  },
  validations() {
    if (this.packages.addPrice.type === "day") {
      return {
        addPrice: {
          numberOfMonthDay: {
            required,
            between: between(1, 30),
          },
          firstRoom: {
            required,
          },
          secondRoom: {
            required,
          },
          thirdRoom: {
            required,
          },
          fourthRoom: {
            required,
          },
          fifthRoom: {
            required,
          },
        },
      };
    } else {
      return {
        addPrice: {
          numberOfMonthDay: {
            required,
            between: between(1, 1),
          },
          firstRoom: {
            required,
          },
          secondRoom: {
            required,
          },
          thirdRoom: {
            required,
          },
          fourthRoom: {
            required,
          },
          fifthRoom: {
            required,
          },
        },
      };
    }
  },
  computed: {
    ...mapState({
      packages: (state) => state.packagesModule,
      priceTypes: (state) => state.packagesModule.priceTypes,
      pricesForTypes: (state) => state.packagesModule.pricesFor,
      addPrice: (state) => state.packagesModule.addPrice,
      calculateByPercents: (state) => state.packagesModule.calculateByPercents,
      packageId: (state) => state.packagesModule.packageId,
      generalInfo: (state) => state.packagesGeneralInfo.generalInfo,
      filterPricesModel: state => state.packagesModule.filterPricesModel,
      isLoading: state => state.appModule.isLoading
    }),
    numberOfMonthDay: {
      get() {
        return this.addPrice.numberOfMonthDay;
      },
      set(value) {
        this.setNumberOfMonthDay(value);
      },
    },
    pricesFor: {
      get() {
        return this.addPrice.priceFor
      },
      set(value) {
        this.setClientType(value)
      }
    },
    firstRoom: {
      get() {
        return this.addPrice.firstRoom;
      },
      set(value) {
        this.setFirstRoom(value);
      },
    },
    secondRoom: {
      get() {
        return this.addPrice.secondRoom;
      },
      set(value) {
        this.setSecondRoom(value);
      },
    },
    thirdRoom: {
      get() {
        return this.addPrice.thirdRoom;
      },
      set(value) {
        this.setThirdRoom(value);
      },
    },
    fourthRoom: {
      get() {
        return this.addPrice.fourthRoom;
      },
      set(value) {
        this.setFourthRoom(value);
      },
    },
    fifthRoom: {
      get() {
        return this.addPrice.fifthRoom;
      },
      set(value) {
        this.setFifthRoom(value);
      }
    }
  },
  methods: {
    ...mapActions({
      editPackage: "packagesModule/editPackage",
      getPackage: "packagesGeneralInfo/getPackage",
      addDefaultPrices: "packagesModule/addDefaultPrices",
      getPrice: "packagesModule/getPrice",
    }),

    ...mapMutations({
      setNumberOfMonthDay: "packagesModule/setNumberOfMonthDay",
      resetPrices: "packagesModule/resetPrices",
      setFirstRoom: "packagesModule/setFirstRoom",
      setSecondRoom: "packagesModule/setSecondRoom",
      setThirdRoom: "packagesModule/setThirdRoom",
      setFourthRoom: "packagesModule/setFourthRoom",
      setFifthRoom: "packagesModule/setFifthRoom",
      setShowPriceList: "packagesModule/setShowPriceList",
      setClientType: 'packagesModule/setClientType',
      setShowCancelBtn: 'packagesModule/setShowCancelBtn',
      resetAddPriceInputs: 'packagesModule/resetAddPriceInputs',
    }),

    async saveData() {
      this.$v.addPrice.$touch();

      const data = {
        interval: this.numberOfMonthDay,
        intervalType: this.packages.addPrice.type === "day" ? "d" : "m",
        clientType: this.packages.addPrice.priceFor === "clients",
        prices: [
          this.firstRoom,
          this.secondRoom,
          this.thirdRoom,
          this.fourthRoom,
          this.fifthRoom,
        ],
      };

      if (!this.$v.addPrice.$error) {
        this.filterPricesModel.priceGroup = ''
        await this.addDefaultPrices({ packageId: this.packageId, data });
        await this.getPrice({
          packageId: this.packageId,
          type: this.packages.addPrice.priceFor === "clients",
        });

        this.resetAddPriceInputs();
        this.$v.$reset();
      }
    },

    cancel() {
      // this.resetPrices()
      if (this.packages.allPriceList && this.packages.allPriceList.length) {
        this.setShowPriceList(true)
        if (this.packages.addPrice.priceFor === 'clients') {
          this.setClientType('resale')
        } else {
          this.setClientType('clients')
        }
      }
    },

    async clientTypeChange(value) {
      this.resetAddPriceInputs();
      this.$v.$reset();
      await this.getPrice({ packageId: this.packageId, type: value === 'clients' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.P-top-block {
  border-bottom: 1px solid #a5aac6;
  padding-bottom: 24px;
  margin-top: 8px;
}

.theme--dark {
  .P-package {
    &__name,
    &__price {
      color: white;
    }

    &__name {
      border-right: 1px solid white;
    }
  }
}
.P-package {
  position: relative;
  &__name,
  &__price {
    font-size: $txt16;
    font-weight: $semiBold;
    color: $secondary-color;
    margin-bottom: 0;
  }
  &__name {
    margin-right: 22px;
    padding-right: 22px;
    border-right: 1px solid $neutral-color;
  }
}

::v-deep {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-text-field__details,
  .v-messages.theme--light {
    display: none;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}

.P-add-price {
  margin-top: 16px;
}

.P-input-block {
  margin-top: 16px;
  .P-number-month {
    max-width: 240px;
    margin-right: 20px;
  }
}
.P-default-prices {
  margin-top: 16px;
  &__inputs {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 22px;
    margin: 16px 0 35px;
  }
}

.P-radio {
  margin-right: 22px;
}
.P-error-block {
  width: 40%;
}

::v-deep {
  .error-massage-cont {
    margin-top: 22px;
    margin-bottom: 0;
  }
}
</style>