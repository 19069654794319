<template>
  <div class="P-save-and-reset-block">
    <ott-button
      v-if="showCancelBtn"
      :text="leftLabel"
      :click="leftClickFunc"
      custom-class="neutral-sm-btn"
    />
    <OttButtonLoader
      @onClick="rightClickFunc"
      :is-loading="isLoading"
      :button-text="rightLabel"
      className="primary-sm-btn"
    />
  </div>
</template>

<script>
  import OttButton from "@/components/vuetifyComponents/OttButton";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  export default {
    name: "SaveResetButtons",
    components: {OttButtonLoader, OttButton},
    props: {
      leftLabel: {
        type: String,
        default: 'Cancel'
      },
      rightLabel: {
        type: String,
        default: 'Save'
      },
      leftClickFunc: {
        type: Function,
        default: () => null
      },
      rightClickFunc: {
        type: Function,
        default: () => null
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      showCancelBtn: {
        type: Boolean,
        default: true,
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-save-and-reset-block {

    display    : flex;
    margin-top : 35px;

    & > :nth-child(2) {
      margin-left : 16px;
    }
  }
</style>
