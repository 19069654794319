<template>
  <selected-filters 
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import SelectedFilters from "@/components/filterComponents/SelectedFilters";

  export default {
    name: "SelectedFiltersList",
    components: {
      SelectedFilters
    },
    computed: {
      ...mapState({
        filterModel: state => state.packagesModule.filterModel,
        filter: state => state.packagesModule.filter,
        priceGroupAdmin: state => state.priceGroupAdmin,
        discountModule: state => state.discountModule,
      }),

      selectedList() {
        return [
          {
            ifCondition: !!this.filterModel.search,
            boxText: `<b>Search</b>: ${this.filterModel.search}`,
            resetArgument: 'search'
          },
          {
            ifCondition: !!this.filterModel.room,
            boxText: `<b>Room</b>: ${this.filterModel.room}`,
            resetArgument: 'room'
          },
          {
            ifCondition: this.filterModel.clientPriceGroup || this.filterModel.clientPriceGroup === null,
            boxText: `<b>Client Price Group</b>: ${this.selectedClientPriceGroup}`,
            resetArgument: 'clientPriceGroup'
          },
          {
            ifCondition: !!this.filterModel.clientDiscounts,
            boxText: `<b>Client Discount</b>: ${this.selectedClientDiscount}`,
            resetArgument: 'clientDiscounts'
          },
          {
            ifCondition: this.filterModel.providerPriceGroup || this.filterModel.providerPriceGroup === null,
            boxText: `<b>Provider Price Group</b>: <span class="text-capitalize">${this.selectedProviderPriceGroup}`,
            resetArgument: 'providerPriceGroup'
          },
          {
            ifCondition: this.filterModel.clients[1] > 0,
            boxText: 
              `<b>Clients</b>: ${this.filterModel.clients[0]} - ${this.filterModel.clients[1]}`,
            resetArgument: 'clients'
          },          
          {
            ifCondition: this.filterModel.clientsTotal[1] > 0,
            boxText: 
              `<b>Clients Total</b>: ${this.filterModel.clients[0]} - ${this.filterModel.clients[1]}`,
            resetArgument: 'clientsTotal'
          },          
          {
            ifCondition: !!this.filterModel.providerDiscounts,
            boxText: '<b>Provider Discount</b>: ' + this.filterModel.providerDiscounts,
            resetArgument: 'providerDiscounts'
          },
          {
            ifCondition: !!this.filterModel.liveService,
            boxText: "<b>Live</b>: Yes",
            resetArgument: 'liveService'
          },
          {
            ifCondition: !!this.filterModel.archiveService,
            boxText: "<b>Archive</b>: Yes",
            resetArgument: 'archiveService'
          },
          {
            ifCondition: !!this.filterModel.timeshiftService,
            boxText: "<b>Timeshift</b>: Yes",
            resetArgument: 'timeshiftService'
          },
          {
            ifCondition: !!this.filterModel.vodService,
            boxText: "<b>VOD</b>: Yes",
            resetArgument: 'vodService'
          },
        ]
      },

      selectedClientPriceGroup() {
        let priceGroupName = ''
        this.priceGroupAdmin.clientsPriceGroups.map(item => {
          if (item.value === this.filterModel.clientPriceGroup) {
            priceGroupName = this.getName(item.name)
          }
        })
        return priceGroupName
      },

      selectedClientDiscount() {
        let discountName = ''
        this.discountModule.clientDiscounts.map(item => {
          if (item.value === this.filterModel.clientDiscounts) {
            discountName = item.name
          }
        })
        return discountName
      },

      selectedProviderPriceGroup() {
        let priceGroupName = ''
        this.priceGroupAdmin.providersPriceGroups.map(item => {
          if (item.value === this.filterModel.providerPriceGroup) {
            priceGroupName = item.name[0].name
          }
        })
        return priceGroupName
      },
    },

    methods: {
      ...mapMutations({
        filterPackages: "packagesModule/filterPackages",        
      }),

      ...mapActions({
        getPackagesList: "packagesModule/getPackagesList",
      }),

      async resetData(type) {
        this.filterModel.resetCurrentData(type);
        this.filterPackages();
        await this.getPackagesList(this.filter);
      },

      getName(nameArray) {
        if (nameArray && nameArray.length) {
          let engName = nameArray.filter(
          (name) => name?.lang === "en" || name?.lang === "EN");
          let ruName = nameArray.filter(
          (name) => name?.lang === "ru" || name?.lang === "RU");

          if (engName && engName.length) {
              return engName[0].name;
          } else {
              return ruName[0].name;
          }
        }
      },
    }
  };
</script>
