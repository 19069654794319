<template>
  <ott-dialog
    v-if="packages.isOpenChannelsModal"
    :is-open="packages.isOpenChannelsModal"
    :show-footer="false"
    :show-header="false"
    show-transparent-header
    @outside="closeModal"
    @resetDataModal="closeModal"
  >
    <template #modalBody>
      <div class="package-info-modal">
        <div class="package-info-modal__body">
          <h3 class="package-info-modal__title">{{ generalInfo.packageName }}</h3>
          <div class="G-flex">
            <p class="information G-flex">
              <span class="information-subtitle">Channels</span>
              <span class="information-content">
                {{ packages.channelListTotalResults ? packages.channelListTotalResults : 0 }}
              </span>
            </p>
            <p class="information G-flex">
              <span class="information-subtitle">Type</span>
              <span v-if="generalInfo.packageType === 'base'" class="G-badge G-base-badge">Base</span>
              <span v-else class="G-badge G-pending-badge">Advanced</span>
            </p>
          </div>

          <div class="general-data">
            <div class="news" v-for="(item, index) in packages.channelsListResult" :key="index">
              <h3 class="news-title">{{ getEngName(item.group.name) }}</h3>
              <div class="news-block">
                <div class="news-item" v-for="(channel, channelIndex) in item.channels" :key="channelIndex">
                    <img :src="'https://iptv.ottstream.live/img/icons/52x39/'+channel.icon_path+'.gif'" :alt="channel.icon_path" class="news-item__icon" width="46"/>
                    <span class="news-item__name">{{ getChannelName(channel.name) }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="G-flex justify-space-between buttons-block">
          <ott-button
            :click="closeModal"
            class="primary-sm-btn"
            text="Back"
            icon-before
            icon-before-class="mdi mdi-keyboard-backspace"
          />
          <ott-button
            class="neutral-sm-btn"
            text="Print channels"
            icon-before
            icon-before-class="mdi mdi-printer"
          />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  // components
  import OttDialog from "@/components/vuetifyComponents/OttDialog";
  import OttButton from "@/components/vuetifyComponents/OttButton";

  export default {
    name: "PackagesListModal",
    components: {OttButton, OttDialog},
    async created() {
      if (this.packageId) {
        await this.getPackage({ packageId: this.packageId })
      }
    },
    computed: {
      ...mapState({
        // important variables vor get list and  change  columns
        packageId: state => state.packagesModule.packageId,
        packages: state => state.packagesModule,
        generalInfo: state => state.packagesGeneralInfo.generalInfo,
      })
    },
    methods: {
      ...mapActions({
        getPackage: 'packagesGeneralInfo/getPackage',
        editPackage: 'packagesModule/editPackage',
      }),
      ...mapMutations({
        setIsOpenChannelsModal: 'packagesModule/setIsOpenChannelsModal',
        resetGeneralInfo: 'packagesGeneralInfo/resetGeneralInfo',
        resetChannelsData: 'packagesModule/resetChannelsData'
      }),
      closeModal() {
        this.setIsOpenChannelsModal(false)
        this.resetGeneralInfo()
        this.resetChannelsData()
      },
      getChannelName(nameArray) {
        if (nameArray && nameArray.length) {
          let engName = nameArray.filter(name => name.lang === 'en')
          if (engName) {
              return engName[0].name
          }
        }
      },
      getEngName(nameArray) {
        if (nameArray && nameArray.length) {
          let engName = nameArray.filter(name => name.lang === 'en')
          if (engName) {
            return engName[0].name
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .package-info-modal {
    &__body {
      padding : 24px 24px 24px 20px;
    }

    &__title {
      font-size     : $txt16;
      font-weight   : $semiBold;
      color         : $secondary-color;
      margin-bottom : 15px;
      margin-left   : 4px;
    }

    .information {
      position : relative;

      &:first-child {
        margin-left: 4px;
      }

      &:not(:first-child) {
        .information-subtitle {
          margin-left : 20px;
        }

        &:after {
          content          : '';
          height           : 14px;
          width            : 1px;
          background-color : $neutral-color;
          position         : absolute;
          bottom           : 4px;
        }
      }

      &-subtitle {
        font-size    : $txt14;
        font-weight  : $semiBold;
        color        : #A5AAC6;
        display      : block;
        margin-right : 20px;
      }

      &-content {
        font-size    : $txt14;
        color        : $secondary-color;
        margin-right : 20px;
      }

      .tag-orange {
        display          : block;
        background-color : $yellow-lighter;
        border           : 1px solid #FEB30059;
        border-radius    : 100px;
        padding          : 0 11px;
        color            : $orange;
      }
    }

    .general-data {
        max-height : 650px;
        overflow-y : auto;
        padding    : 4px 18px 5px 4px;
    }

    .news {
      margin-bottom: 24px;

      &-title {
        font-size     : $txt16;
        font-weight   : $semiBold;
        color         : $secondary-color;
        margin-bottom : 18px;
      }

      .news-block {
        display               : grid;
        grid-template-columns : repeat(4, 1fr);
        grid-gap              : 22px;

        .news-item {
          display          : flex;
          align-items      : center;
          background-color : $white;
          box-shadow       : 0 0 5px #284E6626;
          border-radius    : 4px;
          padding          : 16px 20px;
          width            : 100%;

          &__icon {
            margin-right : 16px;
          }

          &__name {
            font-size   : $txt14;
            font-weight : $semiBold;
            color       : #164367;
          }
        }
      }
    }

    .mb-24 {
      margin-bottom : 24px;
    }

    .buttons-block {
      border-top : 1px solid $neutral-color;
      padding    : 24px;
    }
  }

  @media (max-width: 1400px) {
    .package-info-modal {
      .general-data {
        max-height: 470px;
      }
    }
  }
</style>
